import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

import Services from "./Services";
import Contact from "./Contact";
import image1 from "../../assets/main.png";
import image2 from "../../assets/Software-Development.jpg";
import image3 from "../../assets/digital-marketing-2.png";
import image4 from "../../assets/app-development-1.jpg";
import image5 from "../../assets/uiux2.jpg";
import AboutUs from "../../assets/Screenshot 2024-09-14 164441.png";
import Pakistan from "../../assets/Pakistan.jpeg";
import Canada from "../../assets/Canada.webp";

import "./HomePage.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomePage = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth <= 480) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkScreenSize();

    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: "",
          borderRadius: "10px",
          marginBottom: "50px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    // customPaging: i => (
    //   <div
    //     style={{
    //       width: "30px",
    //       color: "blue",
    //       border: "1px blue solid"
    //     }}
    //   >
    //     {i + 1}
    //   </div>
    // )
  };

  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
    });
  }, []);

  return (
    <Fragment>
      {/* <div className="hero-section">
        <div
          style={{ width: "36%" }}
        //   className="container position-relative"
        //   data-aos="fade-up"
        >
          <p className="hero-text">Welcome to Ebaad Digital</p>
          <p
            className="hero-text"
            style={{ fontSize: "19px", lineHeight: "1.3" }}
          >
            Empowering your business with innovative solutions
          </p>
        </div>
        <div>
          <img
            src={HeroImg}
            className="hero-img"
            // className="hero-img container position-relative"
            // data-aos="fade-down"
          />
        </div>
      </div> */}

      <Slider {...settings} className="slider">
        <div>
          <div className="hero-section-home-page">
            <div
              className="hero-title"
              //style={{ width: "36%" }}
              //   className="container position-relative"
              //   data-aos="fade-up"
            >
              <h2 className="hero-text">Welcome to Ebaad Digital</h2>
              <p
                className="hero-text"
                style={{ fontSize: "19px", lineHeight: "1.3" }}
              >
                Empowering your business with innovative solutions.
                <br /> Delivering excellence through cutting-edge technology.{" "}
                <br />
                Your success is our mission.
              </p>
            </div>
            <div>
              <img
                src={image1}
                className="hero-img"
                alt="image1"
                //style={{ height: "60vh", width: "520px" }}
                // className="hero-img container position-relative"
                // data-aos="fade-down"
              />
            </div>
          </div>
        </div>

        <div>
          <div className="hero-section-home-page">
            <div
              className="hero-title"
              //style={{ width: "36%" }}
              //   className="container position-relative"
              //   data-aos="fade-up"
            >
              <h2 className="hero-text">Software Development</h2>
              <p
                className="hero-text"
                style={{ fontSize: "19px", lineHeight: "1.3" }}
              >
                Tailored solutions that fit your unique business needs.<br /> Turning
                ideas into reality, one line of code at a time. <br />We build
                software that scales with your growth. <br />Bringing innovation to
                every step of development.
              </p>
            </div>
            <div>
              <img
                src={image2}
                className="hero-img"
                alt="image2"
                // className="hero-img container position-relative"
                // data-aos="fade-down"
              />
            </div>
          </div>
          {/* <div
            className="web-development-services-page-hero"
            style={{
              background: `url(${image2}) no-repeat center center/cover`,
            }}
          >
            <div className="services-page-hero-content">
              <h1 className="services-page-title">Software Development</h1>
              <h2
                style={{
                  color: "white",
                  width: "80%",
                  fontWeight: "300",
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                Tailored solutions that fit your unique business needs.
                <br /> Turning ideas into reality, one line of code at a time.{" "}
                <br />
                We build software that scales with your growth. <br />
                Bringing innovation to every step of development. 
              </h2>
            </div>
          </div> */}
        </div>

        <div>
          <div className="hero-section-home-page">
            <div
              className="hero-title"
              //style={{ width: "36%" }}
              //   className="container position-relative"
              //   data-aos="fade-up"
            >
              <h2 className="hero-text">Digital Marketing</h2>
              <p
                className="hero-text"
                style={{ fontSize: "19px", lineHeight: "1.3" }}
              >
                Enhancing your online presence with strategic campaigns. <br />
                Reach your target audience with precision and impact. <br />
                From SEO to social media, we’ve got you covered. <br />
                Grow your brand and drive results with our expert team.
              </p>
            </div>
            <div>
              <img
                src={image3}
                //style={{ height: "60vh", width: "520px" }}
                className="hero-img"
                alt="image3"
                // className="hero-img container position-relative"
                // data-aos="fade-down"
              />
            </div>
          </div>
        </div>

        <div>
          <div className="hero-section-home-page">
            <div
              className="hero-title"
              //style={{ width: "36%" }}
              //   className="container position-relative"
              //   data-aos="fade-up"
            >
              <h2 className="hero-text">Mobile App Development</h2>
              <p
                className="hero-text"
                style={{ fontSize: "19px", lineHeight: "1.3" }}
              >
                Crafting seamless mobile experiences for your users. <br />
                Innovative apps that keep your business connected. <br />
                From concept to launch, we deliver on every platform. <br />
                Stay ahead in the mobile-first world with our solutions.
              </p>
            </div>
            <div>
              <img
                src={image4}
                //style={{ height: "60vh", width: "520px" }}
                className="hero-img"
                alt="image4"
                // className="hero-img container position-relative"
                // data-aos="fade-down"
              />
            </div>
          </div>
        </div>

        <div>
          <div className="hero-section-home-page">
            <div
              className="hero-title"
              //style={{ width: "36%" }}
              //   className="container position-relative"
              //   data-aos="fade-up"
            >
              <h2 className="hero-text">UI/UX Design</h2>
              <p
                className="hero-text"
                style={{ fontSize: "19px", lineHeight: "1.3" }}
              >
                Designs that captivate and engage your audience. Where
                creativity meets functionality. User-focused designs that drive
                conversions. Creating intuitive interfaces that make a lasting
                impact.
              </p>
            </div>
            <div>
              <img
                src={image5}
                className="hero-img"
                alt="image5"
                // className="hero-img container position-relative"
                // data-aos="fade-down"
              />
            </div>
          </div>
        </div>
      </Slider>

      <Services title={1} />

      <div class="about-us-section" id="about-us">
        <h2>About Us</h2>
        <div
          className="about-us-layout"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <img
            src={AboutUs} 
            //className="about-us-image"
             className="container position-relative about-us-image"
            // // style={{ height: "35vh", width: "600px" }}
            data-aos={isMobile ? null : 'fade-right'}
            alt="image6"
          />
          <p 
             className="container position-relative about-us-text"
             data-aos={isMobile ? null : 'fade-left'}
          >
            Welcome to Ebaad Digital, your trusted partner in navigating the
            digital landscape. We specialize in delivering innovative software
            services, including web development, app development, and digital
            marketing. Our team of passionate and skilled professionals is
            dedicated to transforming your ideas into reality, creating custom
            solutions that drive your business forward. At Ebaad Digital, we
            believe in building long-term relationships with our clients by
            providing exceptional service, personalized attention, and a
            commitment to excellence. Join us on your digital journey and let us
            help you achieve your goals with cutting-edge technology and
            creative strategies.
          </p>
        </div>
      </div>

      <div
        class="get-in-touch-section container position-relative"
        data-aos="fade-up"
      >
        <h1>READY TO GROW YOUR BUSINESS</h1>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "-10px",
          }}
        >
          <p className="grow-business-text">
            Unlock your company's full potential with our expert solutions
            tailored to your unique needs. From scaling operations to filling
            critical skill gaps, we're here to support your growth journey. Let
            us help you innovate and achieve your business goals effortlessly.
          </p>
          <Link
            to="/contact"
            style={{ textDecoration: "none", color: "white" }}
          >
            <button className="button">Get Started Today</button>
          </Link>
        </div>
      </div>

      <Contact />
    </Fragment>
  );
};

export default HomePage;
