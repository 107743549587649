import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import Dropdown from "react-bootstrap/Dropdown";
import Logo from "../../assets/ebaad digital final logoo.png";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <Link to="/" className="" style={{ marginLeft: "40px" }}>
        <img src={Logo} style={{ height: "270px" }} />
      </Link>
      <div className="navigation">
        <Link to="/" className="link">
          <p>Home</p>
        </Link>
        <a href="/#about-us" className="link">
          <p>About Us</p>
        </a>
        <a
  href="#about-us"
  className="link"
  onClick={(e) => {
    e.preventDefault(); // Prevent default anchor behavior
    toggleMenu(); // Close the menu

    // Use setTimeout to wait briefly before scrolling
    setTimeout(() => {
      window.location.href = "/#about-us"; // Navigate to the homepage and scroll to the section
    }, 100); // Delay for 100ms or adjust if needed
  }}
>
  <p>About Us</p>
</a>
        <div className="services-dropdown">
          <div className="dropdown-toggle">
            <Link to="/services" className="link">
              <p>Services</p>
            </Link>
          </div>
          <ul className="dropdown-menu">
            <li>
              <Link to="/web-development">Web Development</Link>
            </li>
            <li>
              <Link to="/app-development">App Development</Link>
            </li>
            <li>
              <Link to="/digital-marketing">Digital Marketing</Link>
            </li>
            <li>
              <Link to="/ui-ux-designing">UI/UX Designing</Link>
            </li>
            <li>
              <Link to="/wordpress-development">WordPress Development</Link>
            </li>
            <li>
              <Link to="/graphic-designing">Graphic Designing</Link>
            </li>
          </ul>
        </div>
        {/* <Link to="/careers" className="link">
          <p>Careers</p>
        </Link> */}
      </div>
      <div className="header-contact">
        <p
          style={{
            color: "rgb(48 42 177)",
            marginRight: "20px",
            // fontWeight: "600",
            fontSize: "20px",
          }}
        >
          +1 (647) 915-1599
        </p>
        <button className="button">
          <Link
            to="/contact"
            style={{ textDecoration: "none", color: "white" }}
          >
            Contact Us
          </Link>
        </button>
      </div>

      <button
        className="menu-toggle"
        onClick={toggleMenu}
        style={{ color: "#004AAD" }}
      >
        ☰
      </button>
      {isMenuOpen && (
        <div className="mobile-menu">
          <Link to="/" className="link" onClick={toggleMenu}>
            <p>Home</p>
          </Link>
          <a href="#about-us" className="link" onClick={toggleMenu}>
            <p>About Us</p>
          </a>
          <Link to="/services" className="link" onClick={toggleMenu}>
            <p>Services</p>
          </Link>
          <Link to="/contact" className="link" onClick={toggleMenu}>
            <p>Contact</p>
          </Link>
          {/* <Link to="/careers" className="link" onClick={toggleMenu}>
            <p>Careers</p>
          </Link> */}
        </div>
      )}
    </header>
  );
};

export default Header;
